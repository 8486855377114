<template>
    <div id="g-recaptcha"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'ReCaptchaV2',
    mounted() {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit');
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        document.head.appendChild(script);


        const dataReceived = (token: string) => {
            this.$emit('token', token);
        }
        const onloadCallback = () => {
            (window as any).grecaptcha.render(document.getElementById('g-recaptcha'), {
                'sitekey': process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY,
                'callback': dataReceived
            });

        };

        (window as any).onloadCallback = onloadCallback;
    }
})

</script>
