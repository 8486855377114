/* User: First, last, email, password, confirm, company name Use this both in onboarding and user
editing */

<template>
    <div class="user-info-form">
        <form @submit.prevent="userInfoSubmitted">
            <div class="overflow-hidden sm:rounded-md">
                <div class="">
                    <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                            <label for="first-name" class="onboarding-label">First Name</label>
                            <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                                class="text-input" v-model="v$.firstName.$model" />
                            <div class="input-errors" v-for="error of v$.firstName.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                            <label for="last-name" class="onboarding-label">Last Name</label>
                            <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="text-input"
                                v-model="v$.lastName.$model" />
                            <div class="input-errors" v-for="error of v$.lastName.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4">
                            <label for="email-address" class="onboarding-label">Email</label>
                            <input type="email" name="email-address" id="email-address" autocomplete="email"
                                class="text-input" v-model="v$.email.$model" />
                            <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3" v-if="!isUpdate">
                            <label for="password" class="onboarding-label">Password</label>
                            <input type="password" name="password" id="new-password" autocomplete="new-password"
                                class="text-input" v-model="v$.password.$model" />
                            <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3" v-if="!isUpdate">
                            <label for="confirm-password" class="onboarding-label">Confirm Password</label>
                            <input type="password" name="confirm-password" id="confirm-password" autocomplete="new-password"
                                class="text-input" v-model="v$.confirmPw.$model" />
                            <div class="input-errors" v-for="error of v$.confirmPw.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                        <ReCaptchaV2 v-show="captchaRequired" @token="tokenReceived" />
                    </div>
                    <div class="error-msg" v-if="responseError">{{ responseError }}</div>
                </div>
                <div class="flex flex-row mt-4 items-baseline">
                    <div v-if="!isUpdate">
                        <router-link to="/login">Already have an account?</router-link>
                    </div>
                    <div class="flex-grow"></div>
                    <div>
                        <LoadingButton :disabled="v$.$invalid" :loading="loading">{{
                            isUpdate ? 'Save' : 'Get Started'
                        }}</LoadingButton>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core'
import { required, email, sameAs, helpers } from '@vuelidate/validators'
import { computed, defineComponent, PropType, reactive, toRefs, watch } from 'vue'
import LoadingButton from '@/components/global/LoadingButton.vue'
import { IUserInfoFormData } from '@/models/FormData'
import ReCaptchaV2 from '../global/ReCaptchaV2.vue'

export default defineComponent({
    components: { LoadingButton, ReCaptchaV2 },
    emits: ['user-submitted'],
    props: {
        userData: {
            type: Object as PropType<IUserInfoFormData>,
            required: false,
        },
        isUpdate: {
            default: false,
        },
        loading: {
            default: false,
        },
        captchaRequired: {
            default: false
        }
    },
    setup(props) {
        const { userData, isUpdate, captchaRequired } = toRefs(props)
        const state: IUserInfoFormData = reactive({
            firstName: userData.value?.firstName || '',
            lastName: userData.value?.lastName || '',
            email: userData.value?.email || '',
            password: userData.value?.password || '',
            confirmPw: userData.value?.confirmPw || '',
        })

        const updateRules = {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {},
            confirmPw: {},
        }

        const rules = computed(() => ({
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
            },
            confirmPw: {
                required,
                sameAsPassword: helpers.withMessage(
                    'Password confirmation must match',
                    sameAs(state.password)
                ),
            },
        }))
        const v$ = useVuelidate(isUpdate.value ? updateRules : rules, state)

        watch([userData], () => {
            if (!userData.value) {
                return
            }
            if (userData.value.firstName) {
                state.firstName = userData.value.firstName
            }
            if (userData.value.lastName) {
                state.lastName = userData.value.lastName
            }
            if (userData.value.email) {
                state.email = userData.value.email
            }
            // v$.value.$reset()
        })

        return {
            v$, state,
            captchaRequired
        }
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPw: '',
            responseError: '',
            recaptchaToken: ''
        }

    },
    methods: {
        userInfoSubmitted() {
            this.responseError = '';
            if (this.$props.captchaRequired && !this.recaptchaToken) {
                this.responseError = "Please solve the CAPTCHA to proceed";
                return;
            }
            this.responseError = '';
            this.$emit('user-submitted', {
                'user': this.state,
                'recaptchaToken': this.recaptchaToken
            })
        },
        tokenReceived(token: string) {
            this.recaptchaToken = token;
        },
    }
})
</script>

<style>
button:disabled {
    background-color: gray;
}
</style>
