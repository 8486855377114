/* Nav options: Home Create? Messages My Account / Settings Admin Teams Recommendations */

<template>
    <header class="relative bg-white">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-col w-56 flex-grow-0">
                <router-link to="/dashboard/teams">
                    <img src="../../assets/logo.webp" alt="MoodConnect" />
                </router-link>
            </div>
            <div class="flex-grow"></div>
            <div class="md:hidden pr-6">
                <Popover class="relative">
                    <PopoverButton class="font-bold">
                        <MenuIcon class="w-5 h-5 text-black"></MenuIcon>
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <PopoverPanel class="
                                origin-top-right
                                absolute
                                right-0
                                z-50
                                bg-white
                                mt-4
                                w-56
                                shadow-xl
                                rounded-md
                            ">
                            <div v-if="loggedIn" class="flex flex-col">
                                <PopoverButton><router-link to="/dashboard/teams"
                                        class="text-left block px-4 py-2 w-full">Dashboard</router-link></PopoverButton>
                                <PopoverButton v-if="!msAppLoaded"><router-link to="/marketplace"
                                        class="text-left block px-4 py-2 w-full">Marketplace</router-link>
                                </PopoverButton>
                                <PopoverButton><router-link to="/settings"
                                        class="text-left block px-4 py-2 w-full">Settings</router-link></PopoverButton>
                                <PopoverButton>
                                    <a class="
                                            block
                                            px-4
                                            py-2
                                            font-bold
                                            text-inactive text-left
                                            w-full
                                        " href="#" @click="logoutTapped">Log Out</a>
                                </PopoverButton>
                            </div>
                            <div v-else>
                                <PopoverButton><router-link to="/login" class="text-left block px-4 py-2 w-full">Log
                                        In</router-link></PopoverButton>
                                <PopoverButton><router-link to="/register" class="text-left block px-4 py-2 w-full">Sign
                                        Up</router-link></PopoverButton>
                            </div>
                        </PopoverPanel>
                    </transition>
                </Popover>
            </div>
            <ul class="flex-grow-0 space-x-4 pr-8 items-center hidden md:flex">
                <template v-if="loggedIn">
                    <li>
                        <router-link to="/dashboard/teams" class="block px-4 py-2">Dashboard</router-link>
                    </li>
                    <li v-if="!msAppLoaded">
                        <router-link to="/marketplace" class="block px-4 py-2">Marketplace</router-link>
                    </li>
                    <li>
                        <!-- Add better styling as well -->
                        <Popover class="relative">
                            <PopoverButton class="font-bold text-inactive">My Account</PopoverButton>
                            <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95">
                                <PopoverPanel class="
                                        origin-top-right
                                        absolute
                                        right-0
                                        z-20
                                        bg-white
                                        mt-4
                                        w-56
                                        shadow-xl
                                        rounded-md
                                    ">
                                    <div class="grid">
                                        <PopoverButton><router-link to="/settings"
                                                class="text-left block px-4 py-2">Settings</router-link></PopoverButton>
                                        <PopoverButton>
                                            <a class="
                                                    block
                                                    px-4
                                                    py-2
                                                    font-bold
                                                    text-inactive text-left
                                                " href="#" @click="logoutTapped">Log Out</a>
                                        </PopoverButton>
                                    </div>
                                </PopoverPanel>
                            </transition>
                        </Popover>
                    </li>
                </template>
                <template v-else>
                    <li>
                        <router-link to="/login" class="block px-4 py-2">Log In</router-link>
                    </li>
                    <li>
                        <router-link to="/register" class="block px-4 py-2">Sign Up</router-link>
                    </li>
                </template>
            </ul>
        </div>
        <div id="freetrial" v-if="freeTrialMessage">
            {{ freeTrialMessage }} <router-link class="link" to="/pricing"> Upgrade now</router-link>
            <span id="freetrialclose" v-on:click="deleteFreeTrialMessage">X</span>
        </div>
    </header>
</template>

<script lang="ts">
import { useAuth } from '@/services/auth'
import { defineComponent, onMounted, ref, watch } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MenuIcon } from '@heroicons/vue/solid'
import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        Popover,
        PopoverButton,
        PopoverPanel,
        MenuIcon,
    },
    setup() {
        const { loggedIn, logout, msAppLoaded } = useAuth()

        const router = useRouter()
        let freeTrialMessage = ref('')
        const logoutTapped = async () => {
            await logout()
            router.push('/login')
        }


        // Check if they are on a free-trial and display a message that their free trial will expire in X days.
        const { user } = useAuth();
        watch([user], () => {
            if (!user?.value) {
                deleteFreeTrialMessage()
            }
            const accountAgeInDays: number = Math.floor((new Date().getTime() - new Date(user?.value?.createdAt as string).getTime()) / (24 * 60 * 60 * 1000))
            const remainingFreeTrialDays = 14 - accountAgeInDays;
            const subscriptionExpirationInDays = Math.floor(((user?.value?.company?.subscription?.expiresAt?.getTime() || 0) - new Date().getTime()) / (24 * 60 * 60 * 1000))
            if (accountAgeInDays < 14 && subscriptionExpirationInDays <= 14) {
                freeTrialMessage.value = `Your free trial period will expire in ${subscriptionExpirationInDays} ${subscriptionExpirationInDays > 1 ? 'days' : 'day'}.`;
            }

        })

        const deleteFreeTrialMessage = () => {
            freeTrialMessage.value = '';
        }

        onMounted(() => {
            window.document.title = 'Healthy Workplaces | MoodConnect'
        })

        return {
            loggedIn, logoutTapped, msAppLoaded, freeTrialMessage,
            deleteFreeTrialMessage
        }
    },
})
</script>

<style>
#freetrial {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    padding: 10px 0;
}

#freetrialclose {
    position: absolute;
    font-weight: bold;
    right: 10px;
    background-color: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-family: monospace;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.link {
    color: rgb(0, 0, 85) !important;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
}
</style>
