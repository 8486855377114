<template>
    <div class="login h-full">
        <div class="grid grid-cols-9 place-content-center h-full">
            <div class="col-span-9 md:col-start-3 md:col-span-5">
                <h2 class="text-left text-xl font-medium mb-1">Login</h2>
                <!-- Card -->
                <div class="onboarding-card">
                    <div class="w-full">
                        <form class="space-y-4" @submit.prevent="loginSubmitted">
                            <div class="rounded-md shadow-sm space-y-2">
                                <div>
                                    <label for="email-address" class="onboarding-label">Email address</label>
                                    <input id="email-address" name="email" type="email" autocomplete="email" required="true"
                                        class="text-input" placeholder="Email address" v-model="email" />
                                    <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <div>
                                    <label for="password" class="onboarding-label">Password</label>
                                    <input id="password" name="password" type="password" autocomplete="current-password"
                                        required="true" class="text-input" placeholder="Password" v-model="password" />
                                    <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                                <ReCaptchaV2 @token="tokenReceived" />
                                <div class="error-msg" v-if="responseError">
                                    {{ responseError }}
                                </div>
                            </div>
                            <div class="flex flex-row items-baseline space-x-2">
                                <div>
                                    <router-link to="/forgot">Forgot password?</router-link>
                                </div>
                                <div>
                                    <router-link to="/register">Create an account</router-link>
                                </div>
                                <div class="flex-grow"></div>
                                <div>
                                    <LoadingButton :disabled="v$.$invalid" :loading="loading">Log In</LoadingButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { login, useAuth } from '@/services/auth'
import LoadingButton from '@/components/global/LoadingButton.vue'
import router from '@/router'
import ReCaptchaV2 from '@/components/global/ReCaptchaV2.vue'

export default defineComponent({
    components: { LoadingButton, ReCaptchaV2 },
    setup() {
        const v$ = useVuelidate()
        const { user } = useAuth()
        watch([user], () => {
            if (user?.value?.id) {
                router.replace('/dashboard/teams')
            }
        })
        return { v$ }
    },
    data() {
        return {
            email: '',
            password: '',
            responseError: '',
            loading: false,
            recaptchaToken: ''
        }
    },
    validations() {
        return {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        }
    },
    methods: {
        async loginSubmitted() {
            if (!this.recaptchaToken) {
                this.responseError = 'Please complete the reCAPTCHA'
                return
            }
            this.responseError = '';
            this.loading = true
            const { error } = await login(this.email, this.password, this.recaptchaToken)
            this.loading = false

            if (error.value != undefined) {
                this.responseError = error.value.message
            } else {
                // Route to dashboard
                router.push('/dashboard/teams')
            }
        },
        tokenReceived(token: string) {
            this.recaptchaToken = token;
            this.responseError = '';
        },
    },
    updated() {
        const { user } = useAuth()

        if (user?.value?.id) {
            router.replace('/dashboard/teams')
        }
    },
})
</script>

<style></style>
