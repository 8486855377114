import { Company } from './Company'
import { AUTH_TOKEN } from '../services/auth'
import { isArray } from '@vue/shared'
import { UserInfoFormData } from './FormData'
import UserPaymentMethod from './UserPaymentMethod'

/**
 * All Users in the web app are functionally CompanyUsers as far as the server is concerned
 */
export default class User {
    id: number
    email: string
    firstName: string
    lastName: string
    createdAt: string
    [AUTH_TOKEN]: string

    get fullName(): string {
        return this.firstName + ' ' + this.lastName
    }

    company?: Company
    paymentMethod?: UserPaymentMethod

    constructor(data: any) {
        this.id = data.id
        this.email = data.email
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.createdAt = data.createdAt
        if (isArray(data.companies)) {
            this.company = new Company(data.companies[0])
        }
        // this.subscription = data.subscription
        this.paymentMethod = data.payment

        this[AUTH_TOKEN] = data[AUTH_TOKEN]
    }

    /**
     *
     * @returns UserInfoFormData to pass in for editing
     */
    editFormData() {
        const formData = new UserInfoFormData()
        formData.firstName = this.firstName
        formData.lastName = this.lastName
        formData.email = this.email
        return formData
    }
}
